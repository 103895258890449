// App.js
import React, { useEffect } from 'react';
import './App.css';
import './index.css';
import { Main, Auth, SignUpAuth, Home, TopUp, History, Bank, AuthReset } from './containers';
import { Routes, Route } from 'react-router-dom';
import {  useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from './context/actions/userAction';
import { MainLoader } from './component';
import { motion } from 'framer-motion';
import { fadeInOut } from './animations';
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
  const dispatch = useDispatch();

  // const tawkMessengerRef = useRef();

  const signOut = useSelector(state => state.sign_out.isLoggingOut)

  useEffect(() => {
    // Check if there's session data in localStorage
    const storedUser = localStorage.getItem('user');
  
    if (storedUser !== null) { // Check against null
      try {
         // If session data exists, set the user session
       const userSession = JSON.parse(storedUser);
       dispatch(setUserDetails(userSession));
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
      }
    }
  }, [dispatch]);

  return (
    <div className="w-screen min-h-screen h-auto flex flex-col items-center justify-center bg-cover bg-[url('https://media.threatpost.com/wp-content/uploads/sites/103/2020/11/12130957/darknet-e1605204611698.jpg')]">
       {signOut && (
        <motion.div 
        {...fadeInOut} 
        className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full"
        >
         <MainLoader />
        </motion.div>
      )}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/signupauth" element={<SignUpAuth />} />
        <Route path="/reset-password" element={<AuthReset />} />
        <Route path="/home" element={<Home />} />
        <Route path="/topup/" element={<TopUp />} />
        <Route path="/history" element={<History />} />
        <Route path="/bank/:slug" element={<Bank />} />
      </Routes>

      {/* <TawkMessengerReact
          propertyId="66965f0c32dca6db2cb06587"
          widgetId="1i2tm6i9m"
          ref={tawkMessengerRef}
      /> */}
    </div>
  );
}

export default App;
